import core from "@/utils/core"

export default class Type {
    static check = ["community", "street", "zone"]
    type

    constructor(source) {
        if (source === "published") {
            this.type = { 2: "community", 3: "street", 12: "street", 13: "zone" }[window.sessionStorage.getItem("dimensionId")]
        } else {
            this.type = Type.getUrlType()
        }
    }

    /**
     * 获取标题
     */
    getTitle() {
        return {
            community: "社区周报",
            street: "街镇周报",
            zone: "区周报",
        }[this.type]
    }

    /**
     * 获取请求参数
     */
    getParam() {
        return {
            community: "社区工作周报",
            street: "街道工作周报",
            zone: "区工作周报",
        }[this.type]
    }

    /**
     * 获取类型
     */
    get() {
        return this.type
    }

    /**
     * 获取 url 中的类型值
     */
    static getUrlType() {
        let t = core.getUrlParam("t")
        // 判断是否是合法的类型
        return (t && Type.check.includes(t) ? t : null) || "community"
    }
}
