<template>
    <div class="page">
        <CompTable ref="comp_table" :title="title" :columns="columns" :table-api="api"></CompTable>
        <CompQrCode ref="qr_code">
            <template #title>
                <p class="source">广州市【社区随约服务网上驿站】</p>
                <p class="name">工·作·周·报</p>
            </template>
        </CompQrCode>
    </div>
</template>

<script>
import CompQrCode from "@/views/residentdatabase/components/CompQrCode.vue"
import CompTable from "@/views/jointly/components/comp-table.vue"
import Route from "../communitymobilization/entity/Route"

import proxy from "@/api/proxy"
import Type from "./utils/type"

export default {
    components: {
        CompQrCode,
        CompTable,
    },

    data() {
        let type = new Type()

        return {
            title: type.getTitle(),
            // 周报类型
            type: type.get(),
            // 根据类型判断接口
            api: `/gateway/api/symanage/pc/report/listReportFormItemPage?reportName=${type.getParam()}`,

            columns: [
                {
                    title: "周报时间段",
                    key: "itemPeriod",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "最新生成日期",
                    minWidth: 180,
                    align: "center",
                    render: (h, params) => {
                        return h("p", params.row.generateTime ? this.$core.formatDate(new Date(params.row.generateTime), "yyyy年MM月dd日") : "-")
                    },
                },
                {
                    title: "发布时间",
                    minWidth: 180,
                    align: "center",
                    render: (h, params) => {
                        return h("p", params.row.publishedTime ? this.$core.formatDate(new Date(params.row.publishedTime), "yyyy年MM月dd日") : "-")
                    },
                },
                {
                    title: "审核状态",
                    minWidth: 150,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "p",
                            {
                                5: "待审核",
                                6: "待发布",
                                7: "已发布",
                            }[params.row.status || "5"] || "-"
                        )
                    },
                },
                {
                    title: "操作",
                    align: "center",
                    fixed: "right",
                    minWidth: 250,
                    render: (h, params) => {
                        let status = parseInt(params.row.status || "5")

                        const res = []

                        let audit = h(
                            "Button",
                            {
                                props: {
                                    type: "info",
                                    size: "small",
                                },
                                style: "margin:3px",
                                nativeOn: {
                                    click: () => {
                                        Route.jump("/weeklyreportsaudittable", {
                                            i: params.row.itemId,
                                            d: encodeURIComponent(params.row.itemPeriod),
                                            t: this.type,
                                        })
                                    },
                                },
                            },
                            "审核"
                        )

                        let publish = h(
                            "Button",
                            {
                                props: {
                                    type: "success",
                                    size: "small",
                                },
                                style: "margin:3px",
                                nativeOn: {
                                    click: () => {
                                        this.onPublishReport(params.row.itemId)
                                    },
                                },
                            },
                            "发布"
                        )

                        let look = h(
                            "Button",
                            {
                                props: {
                                    type: "info",
                                    size: "small",
                                },
                                style: "margin:3px",
                                nativeOn: {
                                    click: () => {
                                        Route.jump("/weeklyreportsaudittable", {
                                            i: params.row.itemId,
                                            d: encodeURIComponent(params.row.itemPeriod),
                                            t: this.type,
                                        })
                                    },
                                },
                            },
                            "查看周报"
                        )

                        let read = h(
                            "Button",
                            {
                                props: {
                                    type: "primary",
                                    size: "small",
                                },
                                style: "margin:3px",
                                nativeOn: {
                                    click: () => {
                                        Route.jump("/weeklyreportsread", {
                                            i: params.row.itemId,
                                        })
                                    },
                                },
                            },
                            "查阅情况"
                        )

                        let share = h(
                            "Button",
                            {
                                props: {
                                    type: "success",
                                    size: "small",
                                },
                                style: "margin:3px",
                                nativeOn: {
                                    click: () => {
                                        this.$refs.qr_code.display(`${proxy["/custom"].target}/#/apps/suiyue/pages/weeklyreports/index?t=${this.type}`)
                                    },
                                },
                            },
                            "分享"
                        )

                        switch (status) {
                            case 5:
                                res.push(audit)
                                break
                            case 6:
                                res.push(look, publish)
                                break
                            case 7:
                                res.push(look, read, share)
                                break
                            default:
                                res.push(look)
                        }

                        res.push(
                            h(
                                "Button",
                                {
                                    props: {
                                        size: "small",
                                    },
                                    style: "margin:3px",
                                    nativeOn: {
                                        click: () => {
                                            Route.jump("/weeklyreportsoperate", {
                                                i: params.row.itemId,
                                                d: encodeURIComponent(params.row.itemPeriod),
                                            })
                                        },
                                    },
                                },
                                "操作记录"
                            )
                        )

                        return h(
                            "div",
                            {
                                style: {
                                    padding: "5px 0",
                                },
                            },
                            res
                        )
                    },
                },
            ],
        }
    },

    methods: {
        onPublishReport(id) {
            this.$post("/gateway/api/symanage/pc/report/publishReportForm", {
                itemId: id,
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success("发布成功")
                    // 刷新列表
                    this.$refs.comp_table.refresh()
                } else {
                    this.$Message.error(res.desc)
                }
            })
        },
    },
}
</script>

<style lang="less" scoped>
.source {
    font-size: 16px;
    color: red;
}

.name {
    margin-top: 8px;
    font-size: 28px;
    color: red;
    font-weight: bold;
    letter-spacing: 2px;
}
</style>
